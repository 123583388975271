import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(12),
  },

  head: {
    padding: '14px 8px',

    '&:first-child': {
      paddingLeft: 26,
    },

    '&:last-child': {
      paddingRight: 26,
    },
  },

  body: {
    padding: '12px 8px 9px 8px',

    '&:first-child': {
      paddingLeft: 26,
    },

    '&:last-child': {
      paddingRight: 26,
    },
  },

  paddingCheckbox: {
    width: 48,
    paddingLeft: 12,
    paddingRight: 3,
    '& + td': {
      paddingLeft: 0,
    },

    '&:first-child': {
      paddingLeft: 12,
    },
  },

  actions: {
    verticalAlign: 'middle',
    paddingLeft: 35,
    paddingRight: '35px !important',
  },

  strong: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },

  noBorder: {
    border: 'none',
  },
}));
