import React from 'react';
import cn from 'classnames';
import MUITableCell, { TableCellProps } from '@material-ui/core/TableCell';
import useStyles from './useStyles';

interface Props extends TableCellProps {
  actions?: boolean;
  strong?: boolean;
  noBorder?: boolean;
}

const TableCell = ({ children, actions, strong, className, noBorder, ...rest }: Props) => {
  const classes = useStyles();
  const classNames = cn(classes.root, className, {
    [classes.actions]: actions,
    [classes.strong]: strong,
    [classes.noBorder]: noBorder,
  });

  const overrideClasses = {
    head: classes.head,
    body: classes.body,
    paddingCheckbox: classes.paddingCheckbox,
  };

  return (
    <MUITableCell className={classNames} classes={overrideClasses} {...rest}>
      {children}
    </MUITableCell>
  );
};

TableCell.defaultProps = {
  align: 'left', // eslint-disable-line react/default-props-match-prop-types
};

export default TableCell;
